import Process1 from '../../assets/image/saasModern/process-1.svg';
import Process2 from '../../assets/image/saasModern/process-2.svg';
import Process3 from '../../assets/image/saasModern/process-3.svg';

import MorePasswordManager1 from '../../assets/image/saasModern/more-password-manager-1.svg';
import MorePasswordManager2 from '../../assets/image/saasModern/more-password-manager-2.svg';
import MorePasswordManager3 from '../../assets/image/saasModern/more-password-manager-3.svg';

import FaBuildingO from '../../assets/image/saasModern/fa-building-o.svg';
import FaGroup from '../../assets/image/saasModern/fa-group.svg';
import FaShareAlt from '../../assets/image/saasModern/fa-share-alt.svg';
import FaSitemap from '../../assets/image/saasModern/fa-sitemap.svg';
import SSO from '../../assets/image/saasModern/sso.svg';
import FaObjectGroup from '../../assets/image/saasModern/fa-object-group.svg';
import FaLock from '../../assets/image/saasModern/fa-lock.svg';
import FaServer from '../../assets/image/saasModern/fa-server.svg';
import TwoFa from '../../assets/image/saasModern/2fa.svg';
import FaMobile from '../../assets/image/saasModern/fa-mobile.svg';
import FaFileTextO from '../../assets/image/saasModern/fa-file-text-o.svg';
import FaAreaChart from '../../assets/image/saasModern/fa-area-chart.svg';

import AArch64 from '../../../../landing-gatsby/src/images/download/AArch64.png';
import Android from '../../../../landing-gatsby/src/images/download/android.png';
import AndroidApk from '../../../../landing-gatsby/src/images/download/android-apk.png';
import iOS from '../../../../landing-gatsby/src/images/download/ios.png';
import ARMv7 from '../../../../landing-gatsby/src/images/download/ARMv7.png';
import Chrome from '../../../../landing-gatsby/src/images/download/chrome.png';
import Docker from '../../../../landing-gatsby/src/images/download/docker.png';
import Firefox from '../../../../landing-gatsby/src/images/download/firefox.png';
import Windows from '../../../../landing-gatsby/src/images/download/windows.png';
import Linux from '../../../../landing-gatsby/src/images/download/linux.png';
import Edge from '../../../../landing-gatsby/src/images/download/edge.png';
import Globe from '../../../../landing-gatsby/src/images/download/globe.png';
import SourceCode from '../../../../landing-gatsby/src/images/download/sourcecode.png';
import Splunk from '../../../../landing-gatsby/src/images/download/splunk.png';
import x86_64 from '../../../../landing-gatsby/src/images/download/x86_64.png';
import Version1 from '../../../../landing-gatsby/src/images/versions/version1.png';
import Version2 from '../../../../landing-gatsby/src/images/versions/version2.png';
import Version3 from '../../../../landing-gatsby/src/images/versions/version3.png';
import Version4 from '../../../../landing-gatsby/src/images/versions/version4.png';

import WebclientScreenshot from '../../../../landing-gatsby/src/images/screenshots/webclient.png';
import PortalScreenshot from '../../../../landing-gatsby/src/images/screenshots/portal.png';
import AppScreenshot from '../../../../landing-gatsby/src/images/screenshots/app.png';

import AuthorOne from '../../assets/image/saasModern/author-1.jpg';
import AuthorTwo from '../../assets/image/saasModern/author-2.jpg';
import AuthorThree from '../../assets/image/saasModern/author-3.jpg';

export const MENU_ITEMS = [
  {
    label: 'Documentation',
    path: 'https://doc.psono.com/',
    staticExternalLink: true,
    offset: '0',
  },
  {
    label: 'Security',
    path: '/security',
    staticLink: true,
    offset: '0',
  },
  {
    label: 'Getting Started',
    path: '/getting-started',
    staticLink: true,
    offset: '0',
  },
  {
    label: 'Download',
    path: '/download',
    staticLink: true,
    offset: '0',
  },
];

export const PASSWORD_MANAGER_MORE = [
  {
    image: MorePasswordManager1,
    title: 'More information',
    alt: 'Control more information',
    description:
      'You want to store more than just passwords. Use Psono to protect your banking information, credit card data and social security numbers with military grade encryption.',
  },
  {
    image: MorePasswordManager2,
    title: 'More convenience',
    alt: 'convenient access to your passwords',
    description:
      "You want more convenience using your passwords. Don't manually type usernames and passwords into web forms to login. Let Psono fill out these details for you with just a click.",
  },
  {
    image: MorePasswordManager3,
    title: 'More access',
    alt: 'More access to your passwords',
    description:
      'You want more access to your passwords. Across all your devices and without asking colleagues for passwords or looking them up in excel sheets. Utilize Psono to sync and share passwords securely.',
  },
];

export const ENTERPRISE_BUSINESS_BENEFITS = [
  {
    image: FaBuildingO,
    alt: 'secure enterprise storage',
    title: 'Central Storage',
    description:
      'Get rid of post-its and excel sheets where users manage passwords. Central storage keeps all your passwords in a secure password vault.',
  },
  {
    image: FaShareAlt,
    alt: 'enterprise password management systems',
    title: 'Secure Sharing',
    description:
      "Administrators need to share certain accounts, like root on Linux. Help your Administrators to share passwords and other access codes, so they don't end up in emails or chat logs.",
  },
  {
    image: FaGroup,
    alt: 'enterprise role based access',
    title: 'Role-Based Access Control',
    description:
      'Simplify user management and restrict access on a need-to-know basis. Create entries and folders and share them with users based on groups and user roles. Revoke access when needed.',
  },
  {
    image: FaSitemap,
    alt: 'LDAP Integration',
    title: 'LDAP Integration',
    description:
      "Ease the pain of your administrator to manage users. Use Psono's LDAP integration to streamline user provisioning.",
  },
  {
    image: SSO,
    alt: 'single password for your enterprise users',
    title: 'Single Sign On',
    description:
      "Use a single login for all your tools by integrating Psono into your existing SAML or OIDC identity provider and speed up your employee's workflows.",
  },
  {
    image: FaObjectGroup,
    alt: 'compliance',
    title: 'Compliance',
    description:
      "Enforce company-wide compliance with regulations and guidelines with Psono's extended compliance rules.",
  },
];

export const ENTERPRISE_SECURITY_BENEFITS = [
  {
    image: FaLock,
    alt: 'enterprise password storage',
    title: 'Secure Storage',
    description:
      'Multiple layers of encryption protect your most valuable assets. Client-side encryption, encryption in transit, transport encryption, and encryption at rest.',
  },
  {
    image: FaServer,
    alt: 'enterprise password control',
    title: 'High Available',
    description:
      'Gain the trust of uninterrupted access to your Passwords. Psono business password manager supports high available setups at no extra cost.',
  },
  {
    image: TwoFa,
    alt: 'advanced two factor authentication',
    title: 'Two-Factor',
    description:
      'Improve your security by demanding users to configure multi factor authentication. Various providers like Google Authenticator, Duo, and Yubikey are supported.',
  },
  {
    image: FaMobile,
    alt: 'passwords on your mobile',
    title: 'Mobile Access',
    description:
      'Take your passwords wherever you go on your mobile device. Psono offers mobile apps for iOS and Android in the official stores of Apple and Google.',
  },
  {
    image: FaFileTextO,
    alt: 'Audit logs for password access',
    title: 'Audit Logs',
    description:
      'Obtain the possibility to investigate the behavior of a user. Answer the question about who, when, and what thanks to Psono’s active directory logs.',
  },
  {
    image: FaAreaChart,
    alt: 'compliance reports for security',
    title: 'Security Reports',
    description:
      "Actionable reports inform you about your user's password age, complexity, and length and alarm you about password breaches.",
  },
];

export const PROCESS_ITEMS = [
  {
    image: Process1,
    title: 'Multi Encryption',
    alt: 'Multi Encryption',
    description:
      'Multi level encryption starting with a client side encryption layer, allowing true end to end encryption for password sharing, followed by SSL and storage encryption',
  },
  {
    image: Process2,
    title: 'Open Source',
    alt: 'Open Source',
    description:
      'Transparent public audit possibilities of the complete code. Security comes from proper encryption and not from hiding security flaws.',
  },
  {
    image: Process3,
    title: 'Self Hosted',
    alt: 'Self Hosted',
    description:
      'A self hosted credential manager allows you to host the server on your own and grants you even greater access control capabilities. It also elimates the need to rely on public services for your data.',
  },
];

export const ENTERPRISE_FEATURES = [
  {
    title: 'LDAP & SAML & OIDC SSO Integration',
    alt: 'LDAP & SAML & OIDC SSO Integration',
    description:
      "Authenticate against your company's LDAP Server, SAML or OIDC IDP. Change passwords, manage email addresses and deactivate users in a central place.",
  },
  {
    title: 'Audit Logging',
    alt: 'Audit Logging',
    description:
      'Compliancy and auditing capabilities are one of the security pillars of your company? Then you will love this feature, allowing you to trace every single REST call.',
  },
  {
    title: 'Policies & Compliancy Enforcement',
    alt: 'Policies & Compliancy Enforcement',
    description:
      'Enforce rules on your users to e.g. ensure two factor authentication or disable features like for example export, emergency- or recovery-codes.',
  },
];

export const ENTERPRISE_PRICING_TABLE = [
  {
    id: 'psono_ce',
    hostingType: 'onpremise',
    freePlan: true,
    name: 'Community Edition (CE)',
    titleImage: Version1,
    description: 'Completely free and open source version',
    price: '€0',
    priceLabel: 'Free and open source edition with unlimited users',
    buttonLabel: 'INSTALL NOW',
    url: 'https://doc.psono.com/admin/installation/install-psono-ce.html',
    listItems: [
      {
        content: 'Open Source',
        link: '',
      },
      {
        content: 'Password Generator',
        link: '',
      },
      {
        content: 'Password Sharing',
        link: '',
      },
      {
        content: 'Multifactor Authentication',
        link: '',
      },
      {
        content: '+ More',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Basic Support',
        link: '',
      },
    ],
  },
  {
    id: 'psono_ee_limited',
    hostingType: 'onpremise',
    name: 'Enterprise Edition (EE) limited',
    titleImage: Version2,
    description: 'Free business version for small teams',
    price: '€0',
    priceLabel: 'Free for up to 10 users with complete feature set',
    buttonLabel: 'INSTALL NOW',
    url: 'https://doc.psono.com/admin/installation/install-psono-ce.html',
    listItems: [
      {
        content: 'All community features',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: 'LDAP & SAML & OIDC SSO',
        link: '',
      },
      {
        content: 'Audit Logging',
        link: '',
      },
      {
        content: 'Policies',
        link: '',
      },
      {
        content: 'Compliancy Enforcement',
        link: '',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Basic Support',
        link: '',
      },
    ],
  },
  {
    id: 'psono_ee',
    hostingType: 'onpremise',
    name: 'Enterprise Edition (EE)',
    titleImage: Version3,
    description: 'Enterprise version for bigger companies',
    price: '€2',
    priceLabel: 'per user and month (billed annually, min. 25 users)',
    buttonLabel: 'CONTACT',
    url: 'mailto:sales@psono.com',
    listItems: [
      {
        content: 'All community features',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: 'LDAP & SAML & OIDC SSO',
        link: '',
      },
      {
        content: 'Audit Logging',
        link: '',
      },
      {
        content: 'Policies',
        link: '',
      },
      {
        content: 'Compliancy Enforcement',
        link: '',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Extended Support Option *',
        link: '',
      },
    ],
  },
  {
    id: 'psono_pw',
    hostingType: 'saas',
    name: 'Psono.pw',
    titleImage: Version1,
    description:
      'Community version hosted free of charge as service in the cloud',
    price: '€0',
    priceLabel: 'Free version with complete community feature set',
    buttonLabel: 'SIGN UP',
    url: 'https://www.psono.pw',
    listItems: [
      {
        content: 'Open Source',
        link: '',
      },
      {
        content: 'Password Generator',
        link: '',
      },
      {
        content: 'Password Sharing',
        link: '',
      },
      {
        content: 'Multifactor Authentication',
        link: '',
      },
      {
        content: '+ More',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Basic Support',
        link: '',
      },
    ],
  },
  {
    id: 'psono_saas',
    hostingType: 'saas',
    name: 'SaaS Edition (SaaS EE)',
    titleImage: Version4,
    description: 'Enterprise version hosted as service in the cloud',
    price: '€3',
    priceLabel: 'per user and month (billed annually, min. 25 users)',
    buttonLabel: 'CONTACT',
    url: 'mailto:sales@psono.com',
    listItems: [
      {
        content: 'All community features',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: 'SAML & OIDC SSO',
        link: '',
      },
      {
        content: 'Audit Logging',
        link: '',
      },
      {
        content: 'Policies',
        link: '',
      },
      {
        content: 'Compliancy Enforcement',
        link: '',
      },
      {
        content: 'Hosting & Updates',
        link: '',
      },
      {
        content: 'Extended Support Option *',
        link: '',
      },
    ],
  },
];

export const BUSINESS_PRICING_TABLE = [
  {
    id: 'psono_ce',
    hostingType: 'onpremise',
    freePlan: true,
    name: 'Community Edition (CE)',
    titleImage: Version1,
    description: 'Completely free and open source version',
    price: '€0',
    priceLabel: 'Free and open source edition with unlimited users',
    buttonLabel: 'INSTALL NOW',
    url: 'https://doc.psono.com/admin/installation/install-psono-ce.html',
    listItems: [
      {
        content: 'Open Source',
        link: '',
      },
      {
        content: 'Password Generator',
        link: '',
      },
      {
        content: 'Password Sharing',
        link: '',
      },
      {
        content: 'Multifactor Authentication',
        link: '',
      },
      {
        content: '+ More',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Basic Support',
        link: '',
      },
    ],
  },
  {
    id: 'psono_ee_limited',
    hostingType: 'onpremise',
    name: 'Enterprise Edition (EE) limited',
    titleImage: Version2,
    description: 'Free business version for small teams',
    price: '€0',
    priceLabel: 'Free for up to 10 users with complete feature set',
    buttonLabel: 'INSTALL NOW',
    url: 'https://doc.psono.com/admin/installation/install-psono-ce.html',
    listItems: [
      {
        content: 'All community features',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: 'LDAP & SAML & OIDC SSO',
        link: '',
      },
      {
        content: 'Audit Logging',
        link: '',
      },
      {
        content: 'Policies',
        link: '',
      },
      {
        content: 'Compliancy Enforcement',
        link: '',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Basic Support',
        link: '',
      },
    ],
  },
  {
    id: 'psono_ee',
    hostingType: 'onpremise',
    name: 'Enterprise Edition (EE)',
    titleImage: Version3,
    description: 'Business version for bigger companies',
    price: '€2',
    priceLabel: 'per user and month (billed annually, min. 25 users)',
    buttonLabel: 'CONTACT',
    url: 'mailto:sales@psono.com',
    listItems: [
      {
        content: 'All community features',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: 'LDAP & SAML & OIDC SSO',
        link: '',
      },
      {
        content: 'Audit Logging',
        link: '',
      },
      {
        content: 'Policies',
        link: '',
      },
      {
        content: 'Compliancy Enforcement',
        link: '',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Extended Support Option *',
        link: '',
      },
    ],
  },
  {
    id: 'psono_pw',
    hostingType: 'saas',
    name: 'Psono.pw',
    titleImage: Version1,
    description:
      'Community version hosted free of charge as service in the cloud',
    price: '€0',
    priceLabel: 'Free version with complete community feature set',
    buttonLabel: 'SIGN UP',
    url: 'https://www.psono.pw',
    listItems: [
      {
        content: 'Open Source',
        link: '',
      },
      {
        content: 'Password Generator',
        link: '',
      },
      {
        content: 'Password Sharing',
        link: '',
      },
      {
        content: 'Multifactor Authentication',
        link: '',
      },
      {
        content: '+ More',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: '',
        link: '',
      },
      {
        content: 'Basic Support',
        link: '',
      },
    ],
  },
  {
    id: 'psono_saas',
    hostingType: 'saas',
    name: 'SaaS Edition (SaaS EE)',
    titleImage: Version4,
    description: 'Business version hosted as service in the cloud',
    price: '€3',
    priceLabel: 'per user and month (billed annually, min. 25 users)',
    buttonLabel: 'CONTACT',
    url: 'mailto:sales@psono.com',
    listItems: [
      {
        content: 'All community features',
        link: 'https://doc.psono.com/user/getting-started/features.html',
      },
      {
        content: 'SAML & OIDC SSO',
        link: '',
      },
      {
        content: 'Audit Logging',
        link: '',
      },
      {
        content: 'Policies',
        link: '',
      },
      {
        content: 'Compliancy Enforcement',
        link: '',
      },
      {
        content: 'Hosting & Updates',
        link: '',
      },
      {
        content: 'Extended Support Option *',
        link: '',
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    expend: true,
    title: 'How to contact with Customer Service?',
    description:
      'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response! ',
  },
  {
    title: 'App installation failed, how to update system information?',
    description:
      'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum.',
  },
  {
    title: 'Website reponse taking time, how to improve?',
    description:
      'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum.',
  },
  {
    title: 'New update fixed all bug and issues?',
    description:
      'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us.',
  },
];

export const ENTERPRISE_FAQ_DATA = [
  {
    expend: true,
    title: 'What is enterprise password management?',
    description:
      'Password management for enterprises provides a secure access management solution for different users. It helps keep track of log-ins and manage shared accounts. The privileged access management system allows assigning roles to employees and giving the right people the right access to the tools they need.',
  },
  {
    title: 'How enterprise password manager reduces cyber security risks?',
    description:
      'Enterprise password management solution does all of the work for you. It eliminates written down passwords, gives business password managers the freedom to share access codes, and eliminates repetitive passwords across different platforms. Thanks to Psono’s password management software you can monitor the use of all your passwords, check who have the access to different tools, and be on top of your password security.',
  },
  {
    title:
      'Can my organization trust Psono enterprise password management software?',
    description:
      'Yes, large enterprises and organizations all around the globe trust Psono and also your company can trust your password security to our tool. Psono uses multiple layers of encryption and is security compliant with security standards, such as SOX, HIPAA, and PCI-DSS. So, you can rest assured that your passwords will be safe with Psono.',
  },
  {
    title: 'How safe is the Psono enterprise password management solution?',
    description:
      'Psono enterprise password management system has secure storage with multiple levels of encryption.  On top of that, it has two-factor authentication for its users for extra safety.',
  },
  {
    title: 'How many people can share their passwords using Psono?',
    description:
      'There is no limit to how many people can share their passwords using Psono. You can restrict access to certain people or groups and give it to others based on a need-to-know basis.',
  },
  {
    title: 'What is the cost to implement Psono into a workplace?',
    description:
      'The cost to implement password management software is free for up to 10 users. For larger workplaces, the pricing starts at 2 EUR per user and is for teams of at least 25 users. The cost of password management is directly connected to the size of the company and the number of users it will have to accommodate. The enterprise version for bigger companies comes with support and other features.',
  },
  {
    title: 'Is Psono suitable only for big companies?',
    description:
      'There are some business-specific features that make the work of large companies easier, but they can be implemented in smaller workplaces as well. The need for safe password management and password sharing solution is crucial in every business that operates on a digital platform.',
  },
];

export const INDEX_FAQ_DATA = [
  {
    expend: true,
    title: 'What is a self hosted password manager?',
    description:
      'A password manager that lets you host your passwords on your own server wit the whole data being on premise, in your own network, behind your own firewalls. This means that your virtual password vault is stored on the premise of your business or organization, instead of remotely. This allows you to have more control over your information and access to vault data.',
  },
  {
    title: 'How do I access Psono?',
    description:
      'Psono is available on both computers (MacOS, Windows, Linux) and mobile devices (iOS, Android). You can use it with popular browsers like Chrome, Firefox, and Edge. Enjoy seamless data sync across all your devices, ensuring your information is always at your fingertips.',
  },
  {
    title: 'How does Psono keep your passwords secure?',
    description:
      "Psono ensures your data is fully protected by encrypting it locally on your device before it's sent to their servers. Your encrypted passwords are stored securely in your Psono vault. When you log in again, Psono retrieves your encrypted passwords, which are then decrypted only on your trusted device. This way, your information remains safe and private at all times.",
  },
  {
    title: 'Is Psono both self-hosted and open-source?',
    description:
      "Yes, Psono is a self-hosted and open-source password manager. Our password management system lets you self-host all components on your own company's servers. We take online security very seriously and offer complete source code transparency. Also, Psono password manager allows you to export passwords easily and share them in encrypted form.",
  },
  {
    title: 'Is an open source password manager for teams safe?',
    description:
      "Yes, Psono’s self hosted password managers probably the safest solution your team could be using. As opposed to closed-source managers who don't allow a user to independently review the code source, open-source ones offer all the security features you may need.",
  },
  {
    title: 'Should I use a self hosted password manager for teams?',
    description:
      "You should definitely use a self hosted credential manager as you'll avoid data leaks and protect your privacy at all times. In plain words, open-source is the way to go.",
  },
];

export const FOOTER_WIDGET = [
  {
    title: 'Pages',
    menuItems: [
      {
        url: '/download',
        text: 'Download',
        staticLink: true,
      },
      {
        url: '/features-for-users',
        text: 'Features',
        staticLink: true,
      },
      {
        url: '/password-generator',
        text: 'Password Generator',
        staticLink: true,
      },
      {
        url: '/password-strength-test',
        text: 'Password Strength Test',
        staticLink: true,
      },
      {
        url: '/enterprise-password-manager',
        text: 'Enterprise Password Management',
        staticLink: true,
      },
    ],
  },
  {
    title: 'Our Information',
    menuItems: [
      {
        url: 'https://esaqa.com',
        text: 'About Us',
      },
      {
        url: '/getting-started',
        text: 'Getting Started',
        staticLink: true,
      },
      {
        url: '/blog',
        text: 'Blog',
        staticLink: true,
      },
      {
        url: 'https://doc.psono.com/',
        text: 'Documentation',
      },
      {
        url: 'https://stats.psono.com/',
        text: 'Uptime',
      },
    ],
  },
  {
    title: 'Contact',
    menuItems: [
      {
        url: 'mailto:support@psono.com',
        text: 'support@psono.com',
      },
    ],
  },
  {
    title: 'Address',
    menuItems: [
      {
        text: 'esaqa GmbH',
      },
      {
        text: 'Tiergartenstr. 13',
      },
      {
        text: '91247 Vorra',
      },
      {
        text: 'Germany',
      },
      {
        url: 'mailto:support@psono.com',
        text: 'support@psono.com',
      },
    ],
  },
];

export const FEATURES = [
  {
    title: 'Client Side Encryption',
    description: 'before the data leaves your device',
  },
  {
    title: 'Server Pinning',
    description: "of the server's key",
  },
  {
    title: 'Multilayer Transport Encryption',
    description: 'with TLS 1.2 and Salsa20',
  },
  {
    title: 'Autofill',
    link: 'https://doc.psono.com/user/other/autofill-extensions.html',
    description: 'of all login forms',
  },
  {
    title: 'Encryption at Rest',
    description: 'of all sensitive information',
  },
  {
    title: 'Basic Auth',
    description: 'gets handled automatically',
  },
  {
    title: 'Password Syncing',
    description: 'across all devices',
  },
  {
    title: 'Password Sharing',
    link: 'https://doc.psono.com/user/basic/sharing.html',
    description: 'securely between users',
  },
  {
    title: 'Multifactor Authentication',
    description:
      'with support for <a href="https://doc.psono.com/user/two-factor-authentication/yubikey.html">Yubikey</a>, <a href="https://doc.psono.com/user/two-factor-authentication/duo.html">Duo</a>, <a href="https://doc.psono.com/user/two-factor-authentication/google-authenticator.html">TOTP, e.g.Google Authenticator</a> and <a href="https://doc.psono.com/user/two-factor-authentication/fido2-webauthn.html">Fido\'s webauthn / passkey</a>',
  },
  {
    title: 'Multi Account Support',
    description: 'for websites and applications',
  },
  {
    title: 'Password Generator',
    description:
      'for random passwords, similar to our <a href="/password-generator">online password generator</a>',
  },
  {
    title: 'Security Report',
    description: 'to audit your passwords, age, complexity and length',
  },
  {
    title: 'Secure Notes',
    description: 'to store other information',
  },
  {
    title: 'Bookmarks',
    description: 'as a cherry on top',
  },
  {
    title: 'Password Capture',
    link: 'https://doc.psono.com/user/other/password-capture.html',
    description: 'adds old passwords on the fly',
  },
  {
    title: 'Multi Browser Support',
    description:
      'for <a href="https://addons.mozilla.org/en-US/firefox/addon/psono-pw-password-manager/">Firefox</a>, <a href="https://chromewebstore.google.com/detail/psono-free-password-manag/eljmjmgjkbmpmfljlmklcfineebidmlo">Chrome</a> and <a href="https://microsoftedge.microsoft.com/addons/detail/psono-free-password-man/abobmepfpbkapdlmfhnnkebcnhgeccbm">Edge</a>',
  },
  {
    title: 'Mobile Support',
    description:
      'with a responsive design and apps for <a href="https://play.google.com/store/apps/details?id=com.psono.psono">Android</a> and <a href="https://apps.apple.com/us/app/psono-password-manager/id1545581224">iOS</a>',
  },
  {
    title: 'Password Export',
    link: 'https://doc.psono.com/user/other/export.html',
    description: 'for all stored secrets',
  },
  {
    title: 'Copy to Clipboard',
    description: 'allows usage for normal applications too',
  },
  {
    title: 'Password Import',
    link: 'https://doc.psono.com/user/other/import.html',
    description: 'for Chrome passwords and other password manager',
  },
  {
    title: 'Groups',
    link: 'https://doc.psono.com/user/groups/creation.html',
    description: 'of users and shares for RBAC',
  },
  {
    title: 'Access Control',
    description: 'Possibility to limit rights on shares.',
  },
  {
    title: 'PGP Encryption',
    description: 'allowing to encrypt and decrypt PGP messages.',
  },
  {
    title: 'PGP Mailprovider integration',
    description:
      'allows to encrypt and decrypt gmail, outlook.com and yahoo mails',
  },
  {
    title: 'Offline Mode',
    link: 'https://doc.psono.com/user/other/offline-mode.html',
    description: 'access your passwords even offline',
  },
  {
    title: 'API Keys',
    link: 'https://doc.psono.com/user/api-key/overview.html',
    description:
      'allow the integration of passwords in build pipelines or startup scripts.',
  },
  {
    title: 'Callbacks',
    description:
      'fire callbacks to specific urls whenever a secret changes in order to trigger automated actions e.g. restart XYZ',
  },
  {
    title: 'Emergency Codes',
    link: 'https://doc.psono.com/user/other/emergency-codes.html',
    description:
      'solve the digital legacy problem in case of emergencies or decease.',
  },
  {
    title: 'History of secrets',
    description:
      'Old versions of secrets (e.g. passwords) are stored and are accessible in the history',
  },
  {
    title: 'Broad language support',
    description: 'Psono has been translated in a lot of different languages.',
  },
  {
    title: 'File Sharing',
    description: 'With client side file encryption',
  },
  {
    title: 'High Availability',
    description: 'All components allow to be setuped for HA',
  },
  {
    title: 'Easy integration',
    description: 'Scriptable integration of secrets into your infrastructure',
  },
  {
    title: 'Site-affine Filestorage',
    description:
      'IP based routing allow site affine storage access, remote office setups or cloud hybrid setup',
  },
  {
    title: 'Link Shares',
    link:
      'https://doc.psono.com/user/basic/sharing.html#with-externals-link-shares',
    description:
      "Share secrets and files via link with others, even if they don't have an account",
  },
  {
    title: 'Multiple storage backends',
    description:
      'Supporting local storage, GCP, AWS, Azure... for client side encrypted files',
  },
  {
    title: 'Password Breach Detection',
    description: 'Detects if a password has been part of a known breach',
  },
];

export const CROSS_PLATFORM_CLIENTS = [
  {
    icon: iOS,
    title: 'iOS',
    url: 'https://apps.apple.com/us/app/psono-password-manager/id1545581224',
    installGuideUrl: '',
    description: "Our iOS app in Apple's App Store.",
  },
  {
    icon: Android,
    title: 'Android',
    url: 'https://play.google.com/store/apps/details?id=com.psono.psono',
    installGuideUrl: '',
    description: 'Our Android app in Google Play Store.',
  },
  {
    icon: Chrome,
    title: 'Chrome',
    url:
      'https://chrome.google.com/webstore/detail/psonopw/eljmjmgjkbmpmfljlmklcfineebidmlo',
    installGuideUrl: '',
    description: 'Our Chrome extension in the Google Webstore.',
  },
  {
    icon: Firefox,
    title: 'Firefox',
    url: 'https://addons.mozilla.org/firefox/addon/psono-pw-password-manager/',
    installGuideUrl: '',
    description: 'The Firefox extension in Mozilla Add-ons.',
  },
  {
    icon: Edge,
    title: 'Edge',
    url:
      'https://microsoftedge.microsoft.com/addons/detail/psono-free-password-man/abobmepfpbkapdlmfhnnkebcnhgeccbm',
    installGuideUrl: '',
    description: 'Our Edge extension in the Microsoft Webstore.',
  },
  {
    icon: iOS,
    title: 'Mac',
    url: '',
    installGuideUrl: '',
    description: 'Works on any Mac with the right browser.',
  },
  {
    icon: Windows,
    title: 'Windows',
    url: '',
    installGuideUrl: '',
    description: 'Works on any Windows with the right browser.',
  },
  {
    icon: Linux,
    title: 'Linux',
    url: '',
    installGuideUrl: '',
    description: 'Works on any Linux with the right browser.',
  },
];

export const DOWNLOAD_CLIENTS = [
  {
    icon: SourceCode,
    title: 'Source Code',
    url: 'https://gitlab.com/psono/psono-client',
    installGuideUrl: '',
    description:
      'The source code of the webclient and extensions can be found on GitLab',
  },
  {
    icon: Chrome,
    title: 'Chrome',
    url:
      'https://chrome.google.com/webstore/detail/psonopw/eljmjmgjkbmpmfljlmklcfineebidmlo',
    installGuideUrl: '',
    description: 'Our Chrome extension in the Google Webstore.',
  },
  {
    icon: Firefox,
    title: 'Firefox',
    url: 'https://addons.mozilla.org/firefox/addon/psono-pw-password-manager/',
    installGuideUrl: '',
    description: 'The Firefox extension in Mozilla Add-ons.',
  },
  {
    icon: Edge,
    title: 'Edge',
    url:
      'https://microsoftedge.microsoft.com/addons/detail/psono-free-password-man/abobmepfpbkapdlmfhnnkebcnhgeccbm',
    installGuideUrl: '',
    description: 'Our Edge extension in the Microsoft Webstore.',
  },
  {
    icon: Globe,
    title: 'Web Client',
    url: 'https://www.psono.pw/',
    installGuideUrl: '',
    description:
      'The web client to allow access from any device without installation.',
  },
  {
    icon: Docker,
    title: 'Docker',
    url: 'https://hub.docker.com/r/psono/psono-client/',
    installGuideUrl: '',
    description: 'The frontend of our password manager as docker image.',
  },
];

export const DOWNLOAD_MOBILE = [
  {
    icon: SourceCode,
    title: 'Source Code',
    url: 'https://gitlab.com/psono/psono-app',
    installGuideUrl: '',
    description: "The app's source can be found on GitLab",
  },
  {
    icon: iOS,
    title: 'iOS',
    url: 'https://apps.apple.com/us/app/psono-password-manager/id1545581224',
    installGuideUrl: '',
    description: "Our iOS app in Apple's App Store.",
  },
  {
    icon: Android,
    title: 'Android',
    url: 'https://play.google.com/store/apps/details?id=com.psono.psono',
    installGuideUrl: '',
    description: 'Our Android app in Google Play Store.',
  },
  {
    icon: AndroidApk,
    title: 'Android .apk',
    url: 'https://get.psono.com/psono/psono-app/latest/app.apk',
    installGuideUrl: '',
    description: 'The .apk file for the latest version of the Android app.',
  },
];

export const DOWNLOAD_SERVER = [
  {
    icon: SourceCode,
    title: 'Source Code',
    url: 'https://gitlab.com/psono/psono-server',
    installGuideUrl: '',
    description:
      'The source of our community edition server can be found on GitLab',
  },
  {
    icon: Docker,
    title: 'Docker CE',
    url: 'https://hub.docker.com/r/psono/psono-server/',
    installGuideUrl: '',
    description:
      'The backend of our password manager (community edition) as docker image.',
  },
  {
    icon: Docker,
    title: 'Docker EE',
    url: 'https://hub.docker.com/r/psono/psono-server-enterprise/',
    installGuideUrl: '',
    description:
      'The backend of our password manager (enterprise edition) as docker image.',
  },
];

export const DOWNLOAD_FILESERVER = [
  {
    icon: SourceCode,
    title: 'Source Code',
    url: 'https://gitlab.com/psono/psono-fileserver',
    installGuideUrl: '',
    description: 'The source code of our fileserver can be found on GitLab',
  },
  {
    icon: Docker,
    title: 'Docker',
    url: 'https://hub.docker.com/r/psono/psono-fileserver',
    installGuideUrl:
      'https://doc.psono.com/admin/installation-optional/install-fileserver.html',
    description:
      'The fileserver module of our password manager  as docker image.',
  },
];

export const DOWNLOAD_ADMINCLIENT = [
  {
    icon: SourceCode,
    title: 'Source Code',
    url: 'https://gitlab.com/psono/psono-admin-client',
    installGuideUrl: '',
    description:
      'The source code of our admin webclient can be found on GitLab',
  },
  {
    icon: Docker,
    title: 'Docker',
    url: 'https://hub.docker.com/r/psono/psono-admin-client',
    installGuideUrl: '',
    description:
      'The admin webclient module of our password manager  as docker image.',
  },
];

export const DOWNLOAD_CICDCLIENT = [
  {
    icon: SourceCode,
    title: 'Source Code',
    url: 'https://gitlab.com/psono/psono-ci',
    installGuideUrl: 'https://doc.psono.com/user/psonoci/install.html',
    description:
      'The source code of our client for CI / CD can be found on GitLab',
  },
  {
    icon: x86_64,
    title: 'x86_64 Linux',
    url: 'https://get.psono.com/psono/psono-ci/x86_64-linux/psonoci',
    installGuideUrl: 'https://doc.psono.com/user/psonoci/install.html',
    description: 'The binary of the PsonoCI for x86_64',
  },
  {
    icon: ARMv7,
    title: 'ARMv7 Linux',
    url: 'https://get.psono.com/psono/psono-ci/x86_64-linux/psonoci',
    installGuideUrl: 'https://doc.psono.com/user/psonoci/install.html',
    description: 'The binary of the PsonoCI for ARMv7',
  },
  {
    icon: AArch64,
    title: 'AArch64 Linux',
    url: 'https://get.psono.com/psono/psono-ci/aarch64-linux/psonoci',
    installGuideUrl: 'https://doc.psono.com/user/psonoci/install.html',
    description: 'The binary of the PsonoCI for AArch64',
  },
];

export const DOWNLOAD_OTHERRESOURCES = [
  {
    icon: Splunk,
    title: 'App',
    url: 'https://splunkbase.splunk.com/app/4455/',
    installGuideUrl: '',
    description: "The splunk app for Psono's audit logs",
  },
  {
    icon: Splunk,
    title: 'Add-On',
    url: 'https://splunkbase.splunk.com/app/4456/',
    installGuideUrl: '',
    description: "The splunk add-on for Psono's audit logs",
  },
];

export const SCREENSHOTS = [
  {
    title: 'App',
    image: AppScreenshot,
  },
  {
    title: 'Webclient',
    image: WebclientScreenshot,
  },
  {
    title: 'Admin Client',
    image: PortalScreenshot,
  },
];

export const TESTIMONIALS = [
  {
    title: 'Modern look & trending design 12',
    review:
      'Get working experience 12 to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
    name: 'Jon Doe',
    designation: 'CEO of Dell Co.',
    avatar: `${AuthorOne}`,
  },
  {
    title: 'Modern look & trending design',
    review:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur.',
    name: 'Jon Doe',
    designation: 'Co Founder of IBM',
    avatar: `${AuthorTwo}`,
  },
  {
    title: 'Modern look & trending design 12',
    review:
      'Get working experience 12 to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
    name: 'Jeny Doe',
    designation: 'Manager of Hp co.',
    avatar: `${AuthorThree}`,
  },
];
